import React, { useEffect, useMemo, useState } from "react";
import {
  CCol,
  CRow,
  CCard,
  CCardHeader,
  CCardBody,
  CLabel,
  CLink,
} from "@coreui/react";
import { AxiosError } from "axios";
import _get from "lodash/get";
import _pick from "lodash/pick";
import qs from "query-string";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { getUserDataSelector, UserData } from "../../../../auth";
import { isAllowedAccess } from "../../../../auth/utils";
import { QuestButton } from "../../../../common/components/QuestButton";
import { DATATABLE_MAX_ROWS } from "../../../../common/constants/datatable";
import useDidMount from "../../../../common/hooks/useDidMount";
import { FormMode } from "../../../../common/types/Form";
import { processErrorMessage } from "../../../../error-handler/utils";
import {
  setNotification,
  unsetNotification,
} from "../../../../notification/actions/creators";
import { useNotificationDispatch } from "../../../../notification/dispatchers";
import RefClientAdminForm from "../../../components/RefClientAdminForm";
import RefUserTable from "../../../components/RefUserTable";
import { PERMISSION_IAM } from "../../../constants/permissions";
import useRefUserList from "../../../hooks/useRefUserList";
import ClientService from "../../../services/ClientService";
import { RefClient, RefClientFormData } from "../../../types/ref-client";
import "./index.scss";
import { ListQueryParams } from "../../../types/ref-user";

type UrlParams = {
  clientId: string;
};

const notifId = "create-ref-client";

const RefClientAdmn: React.FC = () => {
  const loggedInUser = useSelector(getUserDataSelector);
  const history = useHistory();
  const urlParams = useParams<UrlParams>();
  const isNewClient = urlParams.clientId === "new";
  const notifDispatch = useNotificationDispatch();
  const [refClient, setRefClient] = useState<RefClient>();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const queryParams: ListQueryParams = useMemo(() => {
    return {
      client: [+urlParams.clientId, ""],
      ...qs.parse(location.search, {
        parseNumbers: true,
        arrayFormat: "comma",
      }),
    };
  }, [location.search, urlParams.clientId]);
  const { refUsers, setQueryParams } = useRefUserList({
    queryParams,
    enabled: false,
  });
  const formData = refClient?.id
    ? _pick(refClient, ["id", "name", "status"])
    : undefined;
  const bdm = refClient?.bdm
    ? `${refClient?.bdm.firstName} ${refClient?.bdm.lastName}`
    : "";

  useEffect(() => {
    if (
      !isAllowedAccess(loggedInUser as UserData, [
        PERMISSION_IAM["IAM.MANAGED_CLIENT.CREATE"],
        PERMISSION_IAM["IAM.MANAGED_CLIENT.UPDATE"],
      ])
    ) {
      return history.replace(`/`);
    }
  }, [loggedInUser, history]);

  const getRefClientDetails = async (refClientId: number) => {
    try {
      setLoading(true);

      const { data } = await ClientService.getRefClient(refClientId);

      setRefClient(data);
    } catch (error) {
      const message = processErrorMessage(error as AxiosError);
      notifDispatch(
        setNotification({
          id: notifId,
          body: message,
          className: "qst-notif-danger",
        })
      );
    }
    setLoading(false);
  };

  const onSubmitHandler = async (data: RefClientFormData) => {
    notifDispatch(unsetNotification(notifId));
    const payload = {
      name: data.name,
      status: data.status,
    };

    try {
      setLoading(true);
      let action: string;
      if (isNewClient) {
        await ClientService.createRefClient(payload);
        action = "created";
      } else {
        await ClientService.updateRefClient(+urlParams.clientId, payload);
        action = "updated";
      }

      notifDispatch(
        setNotification({
          id: notifId,
          body: `Referrer Client successfully ${action}.`,
          className: "qst-notif-success",
        })
      );
      history.push("/iam/ref-clients");
    } catch (error) {
      const message = processErrorMessage(error as AxiosError);
      notifDispatch(
        setNotification({
          id: notifId,
          body: message,
          className: "qst-notif-danger",
        })
      );
    }
    setLoading(false);
  };

  useDidMount(() => {
    if (!isNewClient) {
      getRefClientDetails(+urlParams.clientId);
    }
  });

  return (
    <div className="client-admin">
      <CRow className="quest-page-header-section">
        <CCol xs={12} className="align-items-center d-flex">
          <h2 className="quest-header-text">
            <span data-testid="client-display-name">
              {isNewClient ? "New Referrer Client" : refClient?.name}
            </span>
          </h2>
        </CCol>
      </CRow>
      <CRow className="quest-details-content-section client-admin-content">
        <CCol xl={12}>
          <CCard className="quest-card quest-form">
            <CCardHeader>
              <h3>Referrer Client Admin</h3>
            </CCardHeader>
            <CCardBody>
              <RefClientAdminForm
                data={formData}
                bdm={bdm}
                loading={loading}
                mode={isNewClient ? FormMode.CREATE : FormMode.EDIT}
                onSubmit={onSubmitHandler}
              />
            </CCardBody>
            {!isNewClient && (
              <CCardBody data-testid="userListSection">
                <div className="d-flex justify-content-between mb-3">
                  <CLabel className="d-inline-block">Ref Salespersons</CLabel>
                  <CLink
                    className="d-inline-block"
                    to={`/iam/ref-users/new/admin?client=${urlParams.clientId},${refClient?.name}`}
                  >
                    <QuestButton>Add a new user</QuestButton>
                  </CLink>
                </div>
                <RefUserTable
                  data={refUsers.data}
                  count={refUsers.count}
                  loading={refUsers.loading}
                  page={_get(queryParams, "page", 1)}
                  perPage={_get(queryParams, "limit", DATATABLE_MAX_ROWS)}
                  onParameterChange={setQueryParams}
                  showCompanyColumn={false}
                />
              </CCardBody>
            )}
          </CCard>
        </CCol>
      </CRow>
    </div>
  );
};

export default RefClientAdmn;
