export enum PERMISSION_IAM {
  IAM_ADMIN = "IAM_ADMIN",
  IAM_INTRODUCER_MANAGE = "IAM_INTRODUCER_MANAGE",

  "IAM.CLIENT.READ" = "IAM.CLIENT.READ",
  "IAM.CLIENT.CREATE" = "IAM.CLIENT.CREATE",
  "IAM.CLIENT.UPDATE" = "IAM.CLIENT.UPDATE",
  "IAM.CLIENT.READ_INTRODUCER" = "IAM.CLIENT.READ_INTRODUCER",
  "IAM.CLIENT.UPDATE_INTRODUCER" = "IAM.CLIENT.UPDATE_INTRODUCER",

  "IAM.USER.READ" = "IAM.USER.READ",
  "IAM.USER.CREATE" = "IAM.USER.CREATE",
  "IAM.USER.UPDATE" = "IAM.USER.UPDATE",
  "IAM.USER.READ_INTRODUCER" = "IAM.USER.READ_INTRODUCER",
  "IAM.USER.CREATE_INTRODUCER" = "IAM.USER.CREATE_INTRODUCER",
  "IAM.USER.UPDATE_INTRODUCER" = "IAM.USER.UPDATE_INTRODUCER",
  "IAM.USER.READ_QF_USER" = "IAM.USER.READ_QF_USER",

  "IAM.ROLE.READ" = "IAM.ROLE.READ",
  "IAM.ROLE.CREATE" = "IAM.ROLE.CREATE",
  "IAM.ROLE.UPDATE" = "IAM.ROLE.UPDATE",

  "IAM.MANAGED_USER.READ" = "IAM.MANAGED_USER.READ",
  "IAM.MANAGED_USER.CREATE" = "IAM.MANAGED_USER.CREATE",
  "IAM.MANAGED_USER.UPDATE" = "IAM.MANAGED_USER.UPDATE",
  "IAM.USER_MANAGEMENT_GROUP.CREATE" = "IAM.USER_MANAGEMENT_GROUP.CREATE",
  "IAM.USER_MANAGEMENT_GROUP.UPDATE" = "IAM.USER_MANAGEMENT_GROUP.UPDATE",

  "IAM.MANAGED_CLIENT.READ" = "IAM.MANAGED_CLIENT.READ",
  "IAM.MANAGED_CLIENT.CREATE" = "IAM.MANAGED_CLIENT.CREATE",
  "IAM.MANAGED_CLIENT.UPDATE" = "IAM.MANAGED_CLIENT.UPDATE",
  "IAM.CLIENT_MANAGEMENT_GROUP.CREATE" = "IAM.CLIENT_MANAGEMENT_GROUP.CREATE",
  "IAM.CLIENT_MANAGEMENT_GROUP.UPDATE" = "IAM.CLIENT_MANAGEMENT_GROUP.UPDATE",

  "ASSESSMENT.ACCREDITATION.READ" = "ASSESSMENT.ACCREDITATION.READ",
  "ASSESSMENT.ACCREDITATION.UPDATE" = "ASSESSMENT.ACCREDITATION.UPDATE",

  "ASSESSMENT.APPLICATION.READ" = "ASSESSMENT.APPLICATION.READ",
  "ASSESSMENT.APPLICATION.UPDATE" = "ASSESSMENT.APPLICATION.UPDATE",

  "FUNDER.UPDATE" = "FUNDER.UPDATE",

  "APPLICATION.MOVE" = "APPLICATION.MOVE",
  "APPLICATION.READ_ALL" = "APPLICATION.READ_ALL",
  "APPLICATION.READ" = "APPLICATION.READ",
  "APPLICATION.MANAGE" = "APPLICATION.MANAGE",
}
